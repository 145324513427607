/**
680px: all perfect
below 680px: move user avatar and settings inside menu and remove from header


*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  font-family: "Inter", sans-serif;
}

* {
  scrollbar-width: none;
}

/* Hide scrollbar for Webkit-based browsers (e.g., Chrome, Safari) */
*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-onhover-cursor-to-pointer:hover {
  cursor: pointer;
}

.my-task-create-title:active {
  border: none !important;
  box-shadow: none !important;
}

.my-task-create-title:hover {
  border: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 780px) {
  /*
    menu icon comes inside the header-brand
    navs utilize the whole width
  */
  .header-menu {
    display: none !important;
  }

  .header-box {
    margin-left: 0 !important;
  }

  .header-container {
    padding-left: 0 !important;
  }

  .standalone-menu-phone {
    display: flex !important;
  }

  .header-settings-profile-box {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 818px) {
  .signup-theme-img {
    left: -45%;
  }
}

@media screen and (min-width: 818px) and (max-width: 940px) {
  .signup-theme-img {
    left: -38%;
  }
}

@media screen and (min-width: 940px) and (max-width: 1100px) {
  .signup-theme-img {
    left: -23%;
  }
}

@media screen and (max-width: 1140px) {
  .header-navs {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .signup-theme-img {
    left: -20%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .signup-theme-img {
    left: -8%;
  }
}

@media screen and (min-width: 1300px) {
  .signup-theme-img {
    left: 0;
  }

  .header-brand {
    justify-content: center;
  }

  .header-navs {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1420px) {
  .header-brand {
    justify-content: space-around;
  }
}
